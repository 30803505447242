import { FC, Fragment, useState, MouseEvent } from 'react'

import { useAuth } from 'oidc-react'

import { Popover, Typography, IconButton, Button } from '@material-ui/core'

import AccountIcon from '@material-ui/icons/AccountCircle'

import { useStyles } from './styles'

interface Props {

}

export const Profile: FC<Props> = () => {
    const classes = useStyles()

    const auth = useAuth()

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        handleClose()
        auth.signOutRedirect()
    }

    return (
        <Fragment>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
            >
                <AccountIcon />
            </IconButton>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                    <div className={classes.center}>
                        <Typography>Bienvenido</Typography>
                        <Typography>{auth.userData?.profile.name}</Typography>
                    </div>
                    <Button variant='contained' color='primary' onClick={handleLogout}>Cerrar sesión</Button>
                </div>
            </Popover>
        </Fragment>

    )
}