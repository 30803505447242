import React, { Fragment, useEffect, useState } from "react";
import { FunctionComponent } from "react";


//Grid 
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

//Icons
// import Refresh from '@material-ui/icons/Refresh';
import ClearIcon from '@material-ui/icons/Clear';
// import SearchIcon from '@material-ui/icons/Search';

//Butons
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

//Form
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, PaginationProps } from "../Pagination";

interface EOSTableProps<T> extends PaginationProps {
    columns: GridColDef[],
    rows: T[] | undefined,
    actionButton?: any | undefined,
    tableId: string
}

const useStyles = makeStyles({
    input: {
        minWidth: '100%',
    },
    icon: {
        marginTop: '50%'
    },
    table: {
        border: '0 !important'
    },

});


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const EOSTable: FunctionComponent<EOSTableProps<any>> = ({ columns, rows, actionButton, tableId, pagination, onPaginationChange }) => {
    
    const classes = useStyles();

    const [searchText, setSearchText] = useState('');
    const [searchColumn, setSearchColumn] = useState('');
    const [data, setData] = useState<any>(rows);


    const requestSearch = () => {
        const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
        const filteredData = data?.filter((row: any) => {
            return Object?.keys(row).some((field: any) => {
                console.log(field, searchColumn)
                return searchRegex.test(row[field].toString()) 
            });
        });
        setData(filteredData);
    };
 
    const cleanFilter = () => {
        setData(rows);
        setSearchText('');
        setSearchColumn('');
    };

    useEffect(() => {
        setData(rows);
    }, [rows]);

    return (
        <Fragment>

            <Box mt={3} boxShadow={1} bgcolor={'white'} px={2} py={3} borderRadius={5}>
                <Grid container spacing={3} direction="row" alignItems="center">
                    <Grid item xs={2}>
                        <FormControl variant="outlined" className={classes.input}>
                            <InputLabel id="columnSelector">Columna</InputLabel>
                            <Select labelId="columnSelector" label="Campo" value={searchColumn} onChange={(event) => setSearchColumn(event.target.value as string)}>
                                {
                                    columns.map((col, key) => <MenuItem key={`${col.field}_${key}`} value={col.field}>{col.headerName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <TextField id="outlined-basic" value={searchText} label="Buscar" variant="outlined" onChange={(event) => setSearchText(event.target.value)} />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="Quitar filtro" onClick={cleanFilter}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" color="primary" onClick={requestSearch}>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2} boxShadow={1} bgcolor={'white'} px={4} py={3} borderRadius={5}>

                {actionButton !== void 0 && (
                    <Grid container spacing={3} direction="row-reverse">
                        <Grid item>
                            {actionButton}
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={3} direction="row">
                    <Grid item xs={12}>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid 
                                getRowId={(row) => row[tableId]} 
                                rows={data}
                                columns={columns}
                                paginationMode="server"
                                pagination
                                disableColumnFilter
                                rowsPerPageOptions={[2, 5, 10, 20]}
                                rowCount={pagination?.totalItem || 0}
                                page={pagination?.page || 0}
                                pageSize={pagination?.pageSize || 5}
                                isRowSelectable={() => false}
                                components= {{
                                    Pagination: () => <Pagination pagination={pagination} onPaginationChange={onPaginationChange}/>
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}

export default EOSTable;