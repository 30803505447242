import React from "react";
import { Typography } from "@material-ui/core";
import { FunctionComponent } from "react";

interface TitleProps {
    children: string
}
 
const Title: FunctionComponent<TitleProps> = ({children}) => {
    
    return ( <Typography variant="h5">{children}</Typography> );
}
 
export default Title;