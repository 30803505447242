// Copyright 30-06-2022 por Fabián Quintanilla Ecomsur Holding S.A ©
// Detalle: Se agrega offline_access al scope para el buen funcionamiento de la sesión
import { useAuth } from "oidc-react";

import { Switch, Route } from "react-router-dom";

import { Guard } from "../Guard";

import { GuardedRoute } from "../GuardedRoute";

import { Loading } from "../../pages/Loading";

import { Forbidden } from "../../pages/Forbidden";

import { apps } from "../../apps";

import { Layout } from "../Layout";

import "./App.css";

import axios from "axios";

import { SessionAdmin } from "../SessionAdmin/SessionAdmin";
import { Fragment, useCallback, useEffect } from "react";

export const App = () => {
    const { userData } = useAuth();

    const userToken = useCallback(() => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${
            userData && userData["access_token"]
        }`;
    }, [userData]);

    useEffect(() => {
        userToken();

        return () => {};
    }, [userData, userToken]);

    return (
        <Fragment>
            <SessionAdmin />
            <Guard loadingComponent={Loading}>
                <Layout apps={apps}>
                    <Switch>
                        {apps.map((app) => (
                            <GuardedRoute
                                key={app.name}
                                path={app.url}
                                component={app.component}
                                forbiddenUrl="/forbidden"
                            />
                        ))}
                        <Route path="/forbidden" component={Forbidden} />
                    </Switch>
                </Layout>
            </Guard>
        </Fragment>
    );
};
