import React, { useState } from 'react';
import { GridColDef
     , GridRenderCellParams
    , GridValueGetterParams
 } from '@mui/x-data-grid';
// import Chip from '@material-ui/core/Chip';

import DatagridUserMenu from './components/DatagridUserMenu';
import { FormControlLabel,
    Grid,
    Switch } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import { update } from './api'
import { UserUpdateDto } from './entities/UserUpdateDto';

export const Config = () => {
      
    const userApi = useApi()
    const api = () => { return null}

    const handleSwitchClick = (checked: boolean, user: any) => {
        const updateUser: UserUpdateDto = {
            activo: checked,
            email: user.row["email"],
            twoFactorEnabled: user.row["twoFactorEnabled"],
            nombre: user.row["nombre"],
            apellido: user.row["apellido"],
        }
        
        userApi.callApi(update(user.id,updateUser))
    } 
    
    const columns: GridColDef[] = [
        
        {
        field: 'fullName',
        headerName: 'Nombre',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => `${params.row['nombre'] || ''} ${params.row['apellido'] || ''}`
    },
    {
        field: 'email',
        headerName: 'Email',
        disableColumnMenu: true,
        flex: 1
    },
    
    // {
    //     field: 'activo',
    //     headerName: 'Activo',
    //     disableColumnMenu: true,
    //     type: 'boolean',
    //     flex: 1
    // },
    {
        field: 'activo',
        headerName: '',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (
            params: GridRenderCellParams
            ) => 
            {
            const [check, setCheck] = useState(params.row['activo'])  
                return (
            <Grid container direction="row" alignItems="center" justifyContent="center" >
                 <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                            checked={check}
                            name="checkedB"
                            color="primary"
                            onChange={(_event, checked) => {
                                setCheck(checked);
                                handleSwitchClick(checked, params)
                            }}

                            />
                        }
                        label={params.row['activo'] ? 'Activo' : 'Inactivo'}
                    />
                </Grid>
            </Grid>
        )},
    },
    // {
    //     field: 'products',
    //     headerName: 'Producto',
    //     sortable: false,
    //     disableColumnMenu: true,
    //     flex: 2,
    //     renderCell: (params: GridRenderCellParams) => (
    //         params.row['products'].split(',').map((product: string) => <Chip key={product} label={product} variant="outlined" style={{ marginLeft: 5 }} />)
    //     ),
    // },

    {
        field: 'actions',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (
            // params: GridRenderCellParams
            ) => (
            <Grid container alignItems="center" >
                {/* <Grid item xs>
                    <FormControlLabel
                        control={
                            <Switch
                            checked={params.row['active']}
                                name="checkedB"
                                color="primary"
                                onChange={() => {
                                    params.row['active'] = !params.row['active'];
                                }}
                            />
                        }
                        label={params.row['active'] ? 'Activo' : 'Inactivo'}
                    />
                </Grid> */}
                <Grid item xs={3}>
                    <DatagridUserMenu buttonAction={api}/>
                </Grid>
            </Grid>
        ),
    },
];
    return columns
}