import { FC } from 'react'

import { Typography } from '@material-ui/core'

import { useStyles } from './styles'

interface Props {

}

export const Forbidden: FC<Props> = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography variant='h2' component="span" align="center" >¡Lo Sentimos!</Typography>
            <Typography variant='h5' component="span" align="center">No tienes acceso a esta aplicación.</Typography>
        </div>
    )
}