import { FC } from "react";

import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";

import { Profile } from "../Profile";

import { useStyles } from "./style";

import { NoSignal } from '../NoSignal'

interface Props {
  showMenu: boolean;
  onMenu: () => void;
}

export const Header: FC<Props> = ({ showMenu, onMenu }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        {showMenu && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={onMenu}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography variant="h6" noWrap>
          EOS
        </Typography>

        <div className="grow" />
        <NoSignal />
        <Profile />
      </Toolbar>
    </AppBar>
  );
};
