import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    spacing: {
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    drawerOpen: {
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: theme.drawer.width
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
    },
    apps: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))