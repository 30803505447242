import React, { Fragment } from "react";
import { FunctionComponent } from "react";



import Title from "./Title";
import SubTitle from "./SubTitle";


interface SimpleLayoutProps {
    title: string,
    subtitle: string,
    children?: any
}
 
const SimpleLayout: FunctionComponent<SimpleLayoutProps> = ({ subtitle, title, children}) => {
    // const classes = useStyles();
    return ( 
        <Fragment>
            <SubTitle mb={5}>{subtitle}</SubTitle>
            <Title>{title}</Title>
            {children}
        </Fragment>
     );
}
 
export default SimpleLayout;