import { FC, useState } from "react";
import { useAuth } from "oidc-react";
import axios, { AxiosError } from "axios";
import { ModalExpiredSession } from "./ModalExpiredSession";
import useIdleTimeout from "../../hooks/useIdleTimeout";

interface SessionAdminProps {}

export const SessionAdmin: FC<SessionAdminProps> = () => {
  const authContext = useAuth();
  const [isOpenExpired, setIsOpenExpired] = useState<boolean>(false);
  const [messageExpired, setMessageExpired] = useState<string>("");

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        alert(
          "Su sesión ha expirado y su usuario ha sido desconectado.\r\nOprima Aceptar para regresar a la pantalla de inicio de sesión."
        );
        return authContext
          .signOutRedirect()
          .then((_res) => console.log("signOut"))
          .catch((e) => console.error(e));
      } else {
        return Promise.reject(error);
      }
    }
  );

  useIdleTimeout(15 * 60, () => {
    setIsOpenExpired(true);
    setMessageExpired("Sesión finalizada por inactividad");
  });

  const handleRedirectLogin = () => {
    authContext
      .signOutRedirect()
      .then((_res) => console.log("signOut"))
      .catch((e) => console.error(e));
  };

  return (
    <ModalExpiredSession
      isOpen={isOpenExpired}
      message={messageExpired}
      handleOnAccept={handleRedirectLogin}
    ></ModalExpiredSession>
  );
};
