import { App } from './App'
import axios from "axios";
import { AuthProvider, User, UserManager } from "oidc-react";
import { useHistory } from "react-router-dom";

const {
  REACT_APP_EOS_URL: URL,
  REACT_APP_EOS_URI: URI,
  REACT_APP_EOS_CLIENT_ID: CLIENT,
  REACT_APP_EOS_TENANT: TENANT
} = process.env;
const EOS = `${URL}/${URI}`;

const Index = () =>{

  const history = useHistory();

  const oidcConfig = {
    onSignIn: (user: User | null) => {
      window.location.hash = "";
      history.replace("/");
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        user && user["access_token"]
      }`;
    },
    authority: EOS,
    client_id: CLIENT,
    scope: "openid profile email login offline_access",
    redirect_uri: window.location.origin,
    silent_redirect_uri: window.location.origin,
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code',
    autoSignIn: true,
    automaticSilentRenew: true,
    loadUserInfo: true,
    extraQueryParams: TENANT ? {'tenant': TENANT} : {},
    extraTokenParams: TENANT ? {'tenant': TENANT} : {},
  };

  const userManager = new UserManager({...oidcConfig, })

    return (
        <AuthProvider userManager={ userManager}>
            <App />
        </AuthProvider>
    )
}

export default Index