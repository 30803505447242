import React, { useState, Fragment } from 'react'
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik'
import { FormNavigation } from './FormNavigation'
import { Grid, Step, StepLabel, Stepper } from '@mui/material'
import { useEffect } from 'react'

interface Props extends FormikConfig<FormikValues> {
    actualStep: number
    activeStep: (stepNumber: number) => void
}

export const MultiStepForm = ({
    children,
    initialValues,
    onSubmit,
    activeStep,
    actualStep,
    ...props
}: Props) => {
    const [stepNumber, setStepNumber] = useState(actualStep)
    const [snapshot, setSnapshot] = useState(initialValues)
    const steps = React.Children.toArray(children) as React.ReactElement[]

    const currentStep = steps[stepNumber]
    const totalSteps = steps.length
    const isLastStep = stepNumber === totalSteps - 1

    useEffect(() =>{
        setStepNumber(actualStep)

    },[actualStep])

    const next = (values: FormikValues) => {
        setSnapshot(values)
        setStepNumber((stepNumber) => stepNumber + 1)
        activeStep(stepNumber + 1)
    }

    const previous = (values: FormikValues) => {
        setSnapshot(values)
        setStepNumber((stepNumber) => stepNumber - 1)
    }

    const handleSubmit = async (
        values: FormikValues,
        actions: FormikHelpers<FormikValues>,
    ) => {
        if (currentStep.props.onSubmit) {
            await currentStep.props.onSubmit(values)
        }

        if (isLastStep) {
            return onSubmit(values, actions)
        } else {
            actions.setTouched({})
            next(values)
        }
    }

    return (
        <Fragment>
            <Formik
                initialValues={snapshot}
                onSubmit={handleSubmit}
                validationSchema={currentStep.props.validationSchema}
                {...props}>
                {(formik) => {
                    return (
                        <Form onSubmit={formik.handleSubmit}>
                            <Grid
                                container
                                spacing={4}
                                justifyContent="space-between"
                                direction="row"
                                alignItems="center">
                                <Grid item xs={12}>
                                    <Stepper activeStep={stepNumber}>
                                        {steps.map((actualStep) => {
                                            const label =
                                                actualStep.props.stepName
                                            return (
                                                <Step key={label}>
                                                    <StepLabel>
                                                        {label}
                                                    </StepLabel>
                                                </Step>
                                            )
                                        })}
                                    </Stepper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    {currentStep}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={4}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between">
                                <FormNavigation
                                    displayBackButton={false}
                                    isLastStep={isLastStep}
                                    onBackClick={() => previous(formik.values)}
                                    hasPrevious={stepNumber > 0}
                                />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Fragment>
    )
}

interface FormStepProps
    extends Pick<
        FormikConfig<FormikValues>,
        'children' | 'validationSchema' | 'onSubmit'
    > {
    stepName: string
}
export const FormStep = ({ children }: FormStepProps) => (
    <Fragment>{children}</Fragment>
)
