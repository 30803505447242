import React, {FC} from 'react'

import { 
    TextField, 
    TextFieldProps
} from '@mui/material'

import { useField } from 'formik'

const TextFieldWrapper: FC<TextFieldProps> = ({
    name,
    ...otherProps}) =>
{

    const [field, meta] = useField(name || '');

    const configTextField = {
        ...field,
        ...otherProps
    }

    if (meta && meta.error) {
        configTextField.error = true
        configTextField.helperText = meta.error
    }

    return (
       <TextField {...configTextField}/> 
    )
}

export default TextFieldWrapper