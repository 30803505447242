import React, { Fragment, useState } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { Submenu } from "../../typings/Submenu";
import { MenuItem } from "./MenuItem";

interface MultilevelProps {
  item: Submenu;
  handleSubitemClick: (item: Submenu) => void;
}

export const MultiLevel: React.FC<MultilevelProps> = ({
  item,
  handleSubitemClick,
}) => {
  const { items } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon style={{ alignItems: "flex-start" }}></ListItemIcon>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{
            style: { fontWeight: 900 },
          }}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {items !== undefined &&
            items.map((child: Submenu, key) => {
              return (
                <MenuItem
                  key={key}
                  submenu={child}
                  handleSubitemClick={() => handleSubitemClick(child)}
                />
              );
            })}
        </List>
      </Collapse>
    </Fragment>
  );
};
