import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Submenu } from "../../typings/Submenu";

interface SingleLevelProps {
  item: Submenu;
  handleSubitemClick: (item: Submenu) => void;
}

export const SingleLevel: React.FC<SingleLevelProps> = ({
  item,
  handleSubitemClick,
}) => {
  return (
    <ListItem
      button
      onClick={() => handleSubitemClick(item)}
      style={{ textIndent: item.identation }}
    >
      <ListItemIcon></ListItemIcon>
      <ListItemText primary={item.name} />
    </ListItem>
  );
};
