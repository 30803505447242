import React, { Fragment, MouseEvent, useState } from "react";
import {Menu,
  IconButton,
  MenuItem} from '@mui/material';
import { MoreHoriz }  from '@mui/icons-material';
import { FunctionComponent } from "react";


interface DatagridUserMenuProps {
  buttonAction: () => void
}

const DatagridUserMenu: FunctionComponent<DatagridUserMenuProps> = ({buttonAction}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Fragment>
      <IconButton aria-label="delete" color="primary" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu elevation={1}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={buttonAction}>Editar</MenuItem>
      </Menu>
    </Fragment>
  );
}

export default DatagridUserMenu;