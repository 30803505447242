import * as yup from 'yup'

const nameYup = yup.string().required('Este campo es requerido')

const lastNameYup = yup.string().required('Este campo es requerido')

const emailYup = yup.string().email('Validar formato de correo').required('Este campo es requerido')

export const userSchema = yup.object().shape({
    nombre: nameYup,
    apellido: lastNameYup,
    email: emailYup,
})
