import React from "react";
import {
  Avatar,
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Modal as MaterialModal,
  Theme,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

interface ModalExpiredSessionProps {
  isOpen: boolean;
  message: string;
  handleOnAccept: () => void;
  title?: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      padding: "20px 60px 20px 60px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],

      [theme.breakpoints.up("xs")]: {
        overflow: "scroll",
        maxHeight: "75%",
      },

      [theme.breakpoints.up("md")]: {
        width: 600,
        minHeight: 300,
        overflow: "auto",
        marginTop: 0,
      },
    },
  })
);

export const ModalExpiredSession: React.FC<ModalExpiredSessionProps> = ({
  isOpen,
  title = "Sesión expirada",
  message,
  handleOnAccept: handleAccept,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <MaterialModal
        disableBackdropClick={true}
        open={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12}>
              <List style={{ width: "100%" }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <InfoIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h5" component="h5">
                        {title}
                      </Typography>
                    }
                    secondary={""}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                {message}
              </Typography>
              <Typography variant="h6" component="h6">
                {
                  "Oprima Aceptar para regresar a la pantalla de inicio de sesión."
                }
              </Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </div>
      </MaterialModal>
    </div>
  );
};
