import React, { FC, ComponentType, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import { AxiosConfig } from './utils/AxiosConfig'

import { configuration } from './configuration'
import { Items } from './typings/Config'
import { ApplicationProvider } from './components/Layout/ApplicationProvider'

export interface App {
    name: string
    visibleName: string
    iconUrl: string
    url: string
    component: ComponentType
    submenus?: {
        items?: Items[]
        name: string
        url: string
    }[]
}
export const configs = () => {
    const menuSimple = configuration.submenus.filter((x) => x.url !== '')

    configuration.submenus
        .filter((x) => x.items!.length > 0)
        .forEach(function (x) {
            x.items!.map((x) => menuSimple.push(x))
        })

    const App: FC = () => {
        return (
            <Fragment>
                <AxiosConfig />
                <ApplicationProvider>
                    <Switch>
                        {menuSimple.map((submenu) => {
                            return (
                                <Route
                                    key={submenu.name}
                                    path={`${configuration.url}/${submenu.url}`}
                                    component={submenu.component}
                                />
                            )
                        })}
                    </Switch>
                </ApplicationProvider>
            </Fragment>
        )
    }

    const config: App = {
        ...configuration,
        component: App,
    }

    return config
}
