import { FC } from 'react'

import EosLogo from '../../assets/eos.png'

import { useStyles } from './styles'

interface Props {

}

export const Loading: FC<Props> = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <img className={classes.logo} src={EosLogo} alt='Logo EOS'/>
        </div>
    )
}