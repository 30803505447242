import axios from 'axios'
import { UserCreateDto } from '../entities/UserCreateDto'
import { UserRoleCreateDto } from '../entities/UserRoleCreateDto'
import { UserUpdateDto } from '../entities/UserUpdateDto'

const { REACT_APP_EOS_URL: EOS_URL } = process.env

export const getList = (pageIndex = 0, pageSize = 10) => {
    const url = new URL('api/user', EOS_URL)

    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())

    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`api/user/get/${id}`, EOS_URL)
    return axios.get(url.toString())
}

export const create = (user: UserCreateDto) => {
    const url = new URL('api/user', EOS_URL)
    return axios.post(url.toString(), user)
}

export const createUserRole = (user: UserRoleCreateDto) => {
    const url = new URL('api/userrole', EOS_URL)
    return axios.post(url.toString(), user)
}

export const update = (id: number, user: UserUpdateDto) => {
    const url = new URL(`api/user/${id}`, EOS_URL)
    return axios.put(url.toString(), user)
}
