// Copyright 30-06-2022 por Fabián Quintanilla Ecomsur Holding S.A ©
// Detalle: Se elimina la regla de spacing para pantallas medianas en adelante y se deja solo la regla de xs
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3, 2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.drawer.width
        }
    },
    toolbar: theme.mixins.toolbar,
}))