import React, {FC, Fragment} from 'react'
import { FormikValues } from 'formik'
import { Button, Grid, useTheme } from '@mui/material'

interface Props {
    displayBackButton: boolean
    hasPrevious?: boolean
    isLastStep: boolean
    onBackClick: (values: FormikValues) => void
}
export const FormNavigation: FC<Props> = ({
    displayBackButton, hasPrevious, isLastStep, onBackClick
}) => {
    const theme = useTheme()

    const styles = {
        button: {
            marginLeft: theme.spacing(1),
        },
        actions: {
            margin: 0,
            padding: theme.spacing(2),
        }
    }

  return (
    <Fragment>
        <Grid item>
            {hasPrevious && displayBackButton && (
                <Button variant="outlined" type="button" onClick={onBackClick} sx={styles.button}>
                    Regresar
                </Button>
            )}
        </Grid>
        
        <Grid item alignItems="flex-end" >
            <Button variant="contained" type="submit" color="primary">
                {isLastStep ? 'Guardar' : 'Siguiente'}
            </Button>
        </Grid>
    </Fragment>
  )
}