import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    image: {
        width: '90%',
        maxWidth: 50,
    },
    text: {
        marginLeft: 20,
        whiteSpace: 'nowrap'
    },
    collapse: {
        width: '100%',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    grey: {
        color: theme.palette.grey[600]
    }
}))