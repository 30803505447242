import axios from 'axios'
import { RolesReadDto } from '../entities/RolesReadDto'

const { REACT_APP_EOS_URL: EOS_URL } = process.env

export const getList = (pageIndex = 0, pageSize = 0) => {
    const url = new URL('api/role', EOS_URL)

    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())

    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`api/role/get/${id}`, EOS_URL)
    return axios.get(url.toString())
}

export const create = (role: RolesReadDto) => {
    const url = new URL('api/role', EOS_URL)
    return axios.post(url.toString(), role)
}

export const update = (role: RolesReadDto) => {
    const url = new URL(`api/role/${role.nombre}`, EOS_URL)
    return axios.put(url.toString(), role)
}
