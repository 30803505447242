import { Fragment, FC, useState } from 'react'

import { useTheme, useMediaQuery } from '@material-ui/core'

import { App } from '../../typings/App'

import { Header } from '../Header'

import { Drawer } from '../Drawer'

import { useStyles } from './styles'

interface Props {
    apps?: App[]
}

export const Layout: FC<Props> = ({
    apps,
    children
}) => {
    const [drawerOpen, setDrawerOpen] = useState(false)

    const classes = useStyles()

    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

    return (
        <Fragment>
            <Header onMenu={() => setDrawerOpen(!drawerOpen)} showMenu={isMobile} />
            <Drawer isOpen={!(isMobile && !drawerOpen)} apps={apps}/>
            <div className={classes.content}>
                <div className={classes.toolbar}/>
                { children }
            </div>
        </Fragment>
    )
}