import React, { FC, useEffect } from 'react'
import { Grid, Tabs, Tab, Box, AppBar, FormGroup } from '@mui/material'
import { RolesReadDto } from '../entities/RolesReadDto'
import { useApi } from '../../../hooks/useApi'
import { getList } from '../api'
import CheckBox from '../../../components/Layout/CheckBox'

interface TabPanelProps {
    index: number
    value: number
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ m: 1, p: 2 }}
            {...other}
            width="100%">
            {value === index && children}
        </Box>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

interface Props {}

export const AddProfile: FC<Props> = ({}) => {
    const [value, setValue] = React.useState(0)
    const profileApi = useApi<RolesReadDto[]>()

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const fetchData = () => {
        profileApi.callApi(getList())
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Grid direction="row" container spacing={1}>
            <Grid item xs={12}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="fullWidth"
                        indicatorColor="secondary"
                        textColor="inherit"
                        centered>
                        <Tab label="PERFILES" {...a11yProps(0)} />
                        <Tab label="PERMISOS" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </Grid>
            <TabPanel value={value} index={0}>
                <FormGroup>
                    {profileApi.state.data &&
                        profileApi.state.data.map((profile) => {
                            return (
                                <CheckBox
                                    name="permissions"
                                    key={profile.rolId}
                                    label={profile.nombre}
                                    value={JSON.stringify({rolId: profile.rolId, aplicacionId: profile.aplicacionId})}
                                    legend={''}
                                    labelPlacement="start"
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                />
                            )
                        })}
                </FormGroup>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FormGroup>
                    {profileApi.state.data &&
                        profileApi.state.data.map((profile) => {
                            return (
                                <CheckBox
                                    name="profiles"
                                    key={profile.rolId}
                                    label={profile.nombre}
                                    value={JSON.stringify({rolId: profile.rolId, aplicacionId: profile.aplicacionId})}
                                    legend={''}
                                    labelPlacement="start"
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                />
                            )
                        })}
                </FormGroup>
            </TabPanel>
        </Grid>
    )
}
