import ProfileManagement from './pages/profiles'
import UsersManagement from './pages/users'
import { Config } from './typings/Config'

export const configuration: Config = {
    name: 'admin',
    visibleName: 'EOS',
    iconUrl: 'mpos',
    url: '/admin',
    submenus: [
        {
            name: 'Gestión de usuarios',
            url: 'users',
            roles: ['SSO.SuperAdmin','SSO.Admin'],
            component: UsersManagement,
            items: [],
        },
        {
            name: 'Gestión de perfiles',
            url: 'profiles',
            roles: ['SSO.SuperAdmin','SSO.Admin'],
            component: ProfileManagement,
            items: [],
        },
    ]
}