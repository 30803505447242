import { FC } from 'react'

import { App } from '../../typings/App'

import { 
    Drawer as MaterialDrawer,
    List
} from '@material-ui/core'

import clsx from 'clsx'

import { AppItem } from '../AppItem'

import { useStyles } from './style'

interface Props {
    isOpen: boolean
    apps?: App[]
}

export const Drawer: FC<Props> = ({
    isOpen,
    apps
}) => {
    const classes = useStyles()

    return (
        <MaterialDrawer 
            variant="permanent" 
            className={clsx({
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                    paper: clsx({
                        [classes.drawerOpen]: isOpen,
                        [classes.drawerClose]: !isOpen,
                    })
                }}
            >
            <div className={classes.spacing} />
            <List className={classes.apps}>
                {
                    apps &&
                    apps.map((app) => (
                        <AppItem key={app.name} app={app}/>
                    ))
                }
            </List>
        </MaterialDrawer>
    )
}