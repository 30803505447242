import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@material-ui/core";
import { ThemeProvider as ThemeV5} from "@mui/material"

import { BrowserRouter as Router } from "react-router-dom";

import { theme, themeV5 } from "./theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <ThemeV5 theme={themeV5}>
      <Router>
        <App />
      </Router>
      </ThemeV5>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
