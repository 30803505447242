import { FC, Fragment, ComponentType, createElement } from 'react'

import { useAuth } from 'oidc-react'

interface Props {
    loadingComponent: ComponentType
}

export const Guard: FC<Props> = ({
    loadingComponent,
    children
}) => {
    const auth = useAuth()

    if(auth.isLoading) {
        return createElement(loadingComponent)
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}