import React, { Fragment } from "react";

import { useNavigator } from "../../hooks/useNavigator";

import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import { Typography } from "@material-ui/core";

interface NoSignalProps {}

export const NoSignal: React.FC<NoSignalProps> = () => {
  // obtenemos el estado de conexión desde la api del navegador
  const status = useNavigator();

  // si el estado de false (no conectado) mostramos componente
  const noSignal = !status ? (
    <Fragment>
      <Typography variant="body1">Sin Conexión</Typography>

      <SignalWifiOffIcon style={{ color: "#EED202", padding: "0 5px 0 5px" }} />
    </Fragment>
  ) : null;

  return noSignal;
};
