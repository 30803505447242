import React, { Fragment, FunctionComponent, useReducer } from 'react'
import StepperDialog, {
    Step,
    ButtonDialog,
} from '../../../components/Layout/StepperDialog'
// import * as yup from 'yup';
import { Box, Snackbar } from '@mui/material'
import { useApi } from '../../../hooks/useApi'
import { create, createUserRole } from '../api'
import { UserCreateDto } from '../entities/UserCreateDto'

import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'
import { CreateForm } from '../forms/CreateForm'
import { FormikValues } from 'formik'
import { userSchema } from '../forms/schema'
import { AddProfile } from '../../profiles/components/AddProfile'
import { UserRoleCreateDto } from '../entities/UserRoleCreateDto'
import { useApplication } from '../../../components/Layout/ApplicationProvider'
// import { MultiAppDto } from '../entities/MultiAppDto'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface SnackbarProps {
    open: boolean
    message: string
    severity: AlertColor
}

interface CreateUserStepperProps {}

const CreateUserStepper: FunctionComponent<CreateUserStepperProps> = () => {
    const userApi = useApi()
    const userRoleApi = useApi()

    const { clientAppApi } = useApplication()

    // const [newUser, setNewUser] = useState({ name: '', lastName: '', email: '' });
    const [snackbar, setSnackbar] = useReducer(
        (state: SnackbarProps, newState: Partial<SnackbarProps>) => ({
            ...state,
            ...newState,
        }),
        {
            open: false,
            message: '',
            severity: 'success',
        },
    )

    const handleClose = (
        _event?: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbar({ open: false })
    }

    const submitButton = (_values: FormikValues) => {}

    const submitForm = (values: FormikValues) => {
        const user = values as UserCreateDto

        userApi
            .callApi(create(user))
            .then((userId) => {
                const multiApps = values.profiles.map((profile: string) => {
                    let { rolId, aplicacionId } = JSON.parse(profile)
                    const clientAppId = clientAppApi.state.data?.items.find(
                        (clientApp) => clientApp.aplicacionId === +aplicacionId,
                    )?.aplicacionClienteId

                    return {
                        rolId: rolId,
                        aplicacionClienteId: clientAppId || 0,
                    }
                })

                const userRole: UserRoleCreateDto = {
                    usuarioId: userId,
                    multiApps: multiApps,
                }

                userRoleApi.callApi(createUserRole(userRole)).then( () =>
                    setSnackbar({
                        open: true,
                        message: `Usuario ${values['nombre']} ${values['apellido']} ha sido creado de manera exitosa!!`,
                        severity: 'success',
                    })
                )

            })
            .catch((err) => {
                console.error(err)
                setSnackbar({
                    open: true,
                    message: `Ha ocurrido un error al momento de crear al usuario ${values['nombre']} ${values['apellido']}`,
                    severity: 'error',
                })
            })
    }

    const Profiles: FunctionComponent = () => {
        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    pt: 3,
                }}>
                <CreateForm />
                <AddProfile />
            </Box>
        )
    }

    const steps: Step[] = [
        {
            title: 'Ingresar datos',
            buttonText: 'Siguiente',
            content: <CreateForm />,
            buttonAction: submitButton,
            schema: userSchema,
        },
        {
            title: 'Asignar perfiles',
            buttonText: 'Crear usuario',

            buttonAction: submitButton,
            content: <Profiles />,
            schema: userSchema,
        },
    ]

    const initialValues = {
        nombre: '',
        apellido: '',
        email: '',
        password: '',
        activo: true,
        profiles: [],
    }

    const button: ButtonDialog = {
        text: 'Crear usuario',
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <StepperDialog
                button={button}
                steps={steps}
                initialValues={initialValues}
                submitButton={submitForm}></StepperDialog>
        </Fragment>
    )
}

export default CreateUserStepper
