import React, { FunctionComponent, useState, useRef } from 'react'

import {
    Button,
    DialogContent,
    Grid,
    useTheme,
    Dialog,
    DialogTitle,
    IconButton,
    Step,
} from '@mui/material'
import { Close, ArrowBackIos } from '@mui/icons-material'
import { FormStep, MultiStepForm } from './MultiStepForm'
import { FormikProps, FormikValues } from 'formik'

export interface Step {
    title: string
    buttonText: string
    buttonAction?: Function
    optional?: boolean
    content: React.ReactElement
    schema: any
}

export interface ButtonDialog {
    variant?: 'text' | 'outlined' | 'contained'
    color?: 'inherit' | 'primary' | 'secondary' | 'default'
    text: string
}

interface StepperDialog {
    steps: Step[]
    button: ButtonDialog
    initialValues: FormikValues
    submitButton: (values: FormikValues) => void
}

const StepperDialog: FunctionComponent<StepperDialog> = ({
    steps,
    button,
    initialValues,
    submitButton
}) => {
    const [open, setOpen] = useState(false)
    const [activeStep, setActiveStep] = useState(0)

    const formikFormRef = useRef<FormikProps<FormikValues>>(null)

    const theme = useTheme()
    const styles = {
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        stepper: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        button: {
            marginLeft: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogContent: {
            padding: theme.spacing(2),
        },
        dialogActions: {
            margin: 0,
            padding: theme.spacing(2),
        },
    }
    // Funciones Dialog
    const handleClickOpen = () => {
        setActiveStep(0)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1)
        formikFormRef.current?.values
    }

    return (
        <div>
            <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleClickOpen}>
                {button.text}
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
                // sx={{ '& .MuiDialog-paper': { width: '80%', height: 600 } }}
            >
                <DialogTitle sx={{ p: 2, m: 0 }}>
                    {activeStep !== 0 && (
                        <Button
                            color="primary"
                            startIcon={<ArrowBackIos />}
                            onClick={handleBack}>
                            Volver
                        </Button>
                    )}

                    <IconButton
                        aria-label="close"
                        sx={styles.closeButton}
                        onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 2, m: 0 }}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <MultiStepForm
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    submitButton(values)  
                                    handleClose()
                                }}
                                activeStep={(step) => setActiveStep(step)}
                                innerRef={formikFormRef}
                                actualStep={activeStep}>
                                {steps.length > 0 &&
                                    steps.map((step, index) => {
                                        return (
                                            <FormStep
                                                key={index}
                                                stepName={step.title}
                                                onSubmit={(values) =>
                                                    step.buttonAction?.(values)
                                                }
                                                validationSchema={step.schema}>
                                                {step.content}
                                            </FormStep>
                                        )
                                    })}
                            </MultiStepForm>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default StepperDialog
