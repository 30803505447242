import { MultiLevel } from "./MultiLevel";
import { SingleLevel } from "./SingleLevel";
import { Submenu } from "../../typings/Submenu";

import React from "react";

interface MenuItemProps {
  submenu: Submenu;
  handleSubitemClick: (item: Submenu) => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  submenu,
  handleSubitemClick,
}) => {
  const Component = hasChildren(submenu) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={submenu}
      handleSubitemClick={handleSubitemClick}
    />
  );
};

export function hasChildren(item: Submenu) {
  const { items } = item;
  let boolReturn = true;

  if (items === undefined) {
    boolReturn = false;
  } else if (items.length === 0) {
    boolReturn = false;
  }
  
  return boolReturn;
}
