import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { createTheme, responsiveFontSizes as ResponsiveV5 } from '@mui/material'

// Custom variables definition
declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        drawer: {
            width: number | string;
        };
    }
    interface ThemeOptions {
        drawer?: {
            width: number | string;
        };
    }
}

declare module '@mui/material/styles/createTheme' {
    interface Theme {
        drawer: {
            width: number | string;
        };
    }
    interface ThemeOptions {
        drawer?: {
            width: number | string;
        };
    }
}

export const theme = responsiveFontSizes(createMuiTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(',')
    },
    palette: {
        primary: {
            main: '#004bb1'
        },
        secondary: {
            main: '#04B3FF'
        }
    },
    drawer: {
        width: 300
    },
}))

export const themeV5 = ResponsiveV5(createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(',')
    },
    palette: {
        primary: {
            main: '#004bb1'
        },
        secondary: {
            main: '#04B3FF'
        }
    },
    drawer: {
        width: 300
    },
}))