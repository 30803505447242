import { FC, Fragment, useState } from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";

import ExpandMore from "@material-ui/icons/ExpandMore";

import { useHistory } from "react-router-dom";

import { App } from "../../typings/App";

import { useStyles } from "./styles";

import { useAuth } from "oidc-react";

import { MenuItem } from "../Menu/MenuItem";

import { Submenu } from "../../typings/Submenu";

import { Item } from "../../typings/Item";

interface Props {
  app: App;
}

export const AppItem: FC<Props> = ({ app }) => {
  const classes = useStyles();

  const auth = useAuth();

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const image = require(`../../assets/appsLogos/${app.iconUrl}.png`).default;

  const handleItemClick = () => {
    setIsOpen(!isOpen);

    if (!app.submenus || app.submenus.length === 0) {
      history.push(app.url);
    }
  };

  const handleSubitemClick = (item: Submenu) => {
    if (item) {
      history.push(`${app.url}/${item.url}`);
    }
  };

  const userRoles: string[] = auth.userData?.profile["role"];
  
  
  const valiteUserRolesOnSubmenuRoles = (i: Item) => (i?.roles?.filter((el) => userRoles.includes(el)).length || 0) > 0
  
  const validateRolOnSubmenuItems = (el: Submenu) =>(el?.items?.filter(valiteUserRolesOnSubmenuRoles).length || 0) > 0


  const singleSubmenus = app.submenus
    ?.filter((el) => el.items?.length === 0)
    .filter(valiteUserRolesOnSubmenuRoles) || [];

  const compoundSubmenus = app.submenus?.filter(validateRolOnSubmenuItems) || []

  const rialSubmenus =  [...compoundSubmenus, ...singleSubmenus!];

  return (
    <Fragment>
      <ListItem button onClick={handleItemClick}>
        <ListItemIcon>
          <img
            alt={`logo ${app.visibleName}`}
            className={classes.image}
            src={image}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.text}
          primary={app.visibleName}
          primaryTypographyProps={{
            style: { fontWeight: 900 },
          }}
        />
        {app.submenus &&
          app.submenus.length > 0 &&
          (isOpen ? (
            <ExpandLess className={classes.grey} />
          ) : (
            <ExpandMore className={classes.grey} />
          ))}
      </ListItem>

      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <List component="div" disablePadding>
          {rialSubmenus &&
            rialSubmenus
              .filter((submenu) =>
                submenu.roles?.filter((rol) => userRoles.includes(rol))
              )
              .map((submenu: Submenu, key) => (
                <MenuItem
                  key={key}
                  submenu={submenu}
                  handleSubitemClick={handleSubitemClick}
                />
              ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
