import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      minWidth: 320,
      width: "30%",
      animation: `$pulse 2000ms ${theme.transitions.easing.sharp} infinite`,
    },
    loading: {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      letterSpacing: 3,
    },
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  })
);
