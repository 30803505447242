import React, { FC } from 'react'

// import { useApi } from '../../../hooks/useApi'

import { 
    Grid
} from '@mui/material'

import TextField from '../../../components/Layout/TextField'
interface Props {
}

export const CreateForm: FC<Props> = ({

}) => {
    return (
        <Grid direction="row" container spacing={1}>
            <Grid item xs={4}>
            <TextField
                name="nombre"
                label="Nombre"
                inputProps={{ maxLength: 50 }}
            />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="apellido"
                    label="Apellido"
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="email"
                    label="Correo"
                    inputProps={{ maxLength: 70 }}
                />
            </Grid>
        </Grid>
    )
}
