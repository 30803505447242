import React, {FC} from 'react'

import { 
    Checkbox, 
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    CheckboxProps,
    FormControlProps
} from '@mui/material'

import { useField, useFormikContext } from 'formik'

type Props = {
    legend: string
    label: React.ReactNode
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}
const CheckBoxWrapper: FC<CheckboxProps & Props> = ({
    name,
    label,
    legend,
    labelPlacement,
    sx,
    ...otherProps}) =>
{
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name || '');

    const handleChange = (event: React.ChangeEvent<any>) => {
        const { value, checked} = event.target
        if (checked) {
            setFieldValue(name || '', Array.isArray(field.value) ? [...field.value, value] : value)
          } else {
            setFieldValue(name || '', Array.isArray(field.value) ? field.value.filter((v) => v !== value) : value)
          }
    }
    
    const configCheckbox = {
        ...field,
        ...otherProps,
        onChange: handleChange
    }

    const configFormControl: FormControlProps = {

    }

    if (meta && meta.touched && meta.error) {
        configFormControl.error = true
    }

    return (
       <FormControl {...configFormControl}>
        <FormLabel component="legend">{legend}</FormLabel>
        <FormGroup>
            <FormControlLabel 
                control={<Checkbox {...configCheckbox} />}
                label={label}
                labelPlacement={labelPlacement}
                sx={sx}/>
        </FormGroup>
       </FormControl>
    )
}

export default CheckBoxWrapper