import React, {Fragment, useEffect} from 'react';
import {
    GridColDef,
    GridRenderCellParams
} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

import SimpleLayout from '../../components/Layout/SimpleLayout';
import EOSTable from '../../components/Layout/EOSTable';
import { ApiResponse } from '../../typings/ApiResponse';
import { useApi } from '../../hooks/useApi';
import { usePagination } from '../../hooks/usePagination';
import { getList } from './api'
import { RolesReadDto } from './entities/RolesReadDto';
import { State } from '../../components/_EOS/State'
import { useApplication } from '../../components/Layout/ApplicationProvider';

 
const ProfileManagement = () => {


    const {state, callApi} = useApi<ApiResponse<RolesReadDto>>()
    const pagination = usePagination(5)
    const {appApi} = useApplication()
    
    const fetchData = (page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    },[])

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const columns: GridColDef[] = [
        {
            field: 'nombre',
            headerName: 'Perfil',
            width: 250,
        },
        {
            field: 'aplicacionId',
            headerName: 'Producto',
            sortable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    key={params.row['aplicacionId']}
                    label={
                        appApi.state.data?.items.find(
                            (application) =>
                                application.aplicacionId ===
                                +params.row['aplicacionId'],
                        )?.nombre
                    }
                    variant="outlined"
                    style={{ marginLeft: 5 }}
                />
            ),
        },
    ]

    return (
        <SimpleLayout title='Gestión de perfiles' subtitle='Gestión de perfiles'>
            <State state={state} loadingMessage='Cargando perfiles'>
                <EOSTable tableId='rolId' columns={columns} rows={state.data?.items} actionButton={<Fragment />} 
                pagination={{
                    totalItem: state.data?.totalItems || 0,
                    page: pagination.page,
                    pageSize: pagination.pageSize
                }} onPaginationChange={handlePaginationChange}></EOSTable>
            </State>
        </SimpleLayout>
    );
}


export default ProfileManagement;