import React, { useEffect } from 'react';
import SimpleLayout from '../../components/Layout/SimpleLayout';
import EOSTable from '../../components/Layout/EOSTable';
import { Config } from "./Config";

import CreateUserStepper from './components/CreateUserStepper';
import { useApi } from '../../hooks/useApi';
import { UserReadDto } from './entities/UserReadDto';
import { ApiResponse } from '../../typings/ApiResponse';
import { usePagination } from '../../hooks/usePagination';
import { getList } from './api'
import { State } from '../../components/_EOS/State'

const UsersManagement = () => {
    
    const {state, callApi} = useApi<ApiResponse<UserReadDto>>()
    const pagination = usePagination(5)
    
    const fetchData = (page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    },[])

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    return (
        <SimpleLayout title='Gestión de usuarios' subtitle='Gestión de usuarios'>
            <State state={state} loadingMessage='Cargando Usuarios'>
                <EOSTable tableId='usuarioId' columns={Config()} rows={state.data?.items} actionButton={<CreateUserStepper />} 
                pagination={{
                    totalItem: state.data?.totalItems || 0,
                    page: pagination.page,
                    pageSize: pagination.pageSize
                }} onPaginationChange={handlePaginationChange}></EOSTable>
            </State>
        </SimpleLayout>
    );
}

export default UsersManagement;