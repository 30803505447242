import React from "react";
import { Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { makeStyles } from '@material-ui/core/styles';

interface SubTitleProps {
    children: string,
    m?: number | string,
    mt?: number | string,
    mr?: number | string,
    mb?: number | string,
    ml?: number | string,
}
 
const SubTitle: FunctionComponent<SubTitleProps> = (props) => {
    
    //Margin css
    const m = props.m === void 0? 'inherit' : props.m;
    const mt = props.mt === void 0? 'inherit' : props.mt;
    const mr = props.mr === void 0? 'inherit' : props.mr;
    const mb = props.mb === void 0? 'inherit' : props.mb;
    const ml = props.ml === void 0? 'inherit' : props.ml;

    const useStyles = makeStyles({
        subTitle: {
            margin: `${m}px`,
            marginTop: `${mt}px`,
            marginRight: `${mr}px`,
            marginBottom: `${mb}px`,
            marginLeft: `${ml}px`,
        }
    });
    const classes = useStyles();
    
    return ( <Typography className={classes.subTitle} variant="subtitle1">{ props.children}</Typography> );
}
 
export default SubTitle;