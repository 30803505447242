import React, { FC, useContext, useEffect, useMemo } from 'react'
import { getApps } from '../../api/application'
import { getClientApps } from '../../api/clientApp'

import { useApi, ReturnType } from '../../hooks/useApi'
import { ApiResponse } from '../../typings/ApiResponse'
import { ApplicationDto } from '../../typings/Application/ApplicationDto'
import { ClientApplicationDto } from '../../typings/ClientApplication/ClientApplicationDto'

interface ApplicationContext {
    appApi: ReturnType<ApiResponse<ApplicationDto>>
    clientAppApi: ReturnType<ApiResponse<ClientApplicationDto>>
}

interface Props {
}

const ApplicationContext = React.createContext<ApplicationContext | undefined>(undefined)

export const ApplicationProvider: FC<Props> = ({
    children
}) => {
    const appApi = useApi<ApiResponse<ApplicationDto>>()
    const clientAppApi = useApi<ApiResponse<ClientApplicationDto>>()

    useEffect(() => {
        appApi.callApi(getApps())
        clientAppApi.callApi(getClientApps())
    }, [])

    const data = useMemo(() => ({
        appApi,
        clientAppApi
    }), [appApi])

    return (
        <ApplicationContext.Provider value={data}>
            { children }
        </ApplicationContext.Provider>
    )
}

export const useApplication = () => {
    const context = useContext(ApplicationContext)

    if (context === undefined) {
        throw new Error('useApplication should be inside ApplicationProvider')
    }

    return context
}
