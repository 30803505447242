import { FC } from 'react'

import { Redirect, Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
    forbiddenUrl?: string
}

export const GuardedRoute: FC<Props> = ({
    forbiddenUrl = '/',
    ...props
}) => {
    
    // TODO: Validar roles

    if(false) {
        return <Redirect to={forbiddenUrl}/>
    }

    return (
        <Route {...props}/>
    )
}